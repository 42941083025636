@tailwind base;
@tailwind components;
@tailwind utilities;
@import '../../../node_modules/@syncfusion/ej2-tailwind-theme/styles/tailwind.css';


.grecaptcha-badge {
    width: 70px !important;
    overflow: hidden !important;
    transition: all 0.3s ease !important;
    left: 4px !important;
  }
  .grecaptcha-badge:hover {
    width: 256px !important;
  }

  ul {
    li{
        -webkit-margin-start: 0.25rem;
                margin-inline-start: 0.25rem;
      }
    }
  ul li:first-child{
    -webkit-margin-start: 0px;
            margin-inline-start: 0px;
  }
  a{
    cursor: pointer;
  }
  ::-webkit-scrollbar-track {
    border-radius: 10px;
  }
  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #d9d9d9;
  }
