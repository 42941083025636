/* // _general.scss */

/*********************************/
/*             General            */
/*===============================*/
p {
    @apply leading-relaxed;
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
    @apply leading-normal;
}

::selection {
    @apply bg-indigo-600/90 text-white;
}

@media (max-width: 640px) {
    [data-type] iframe, [data-type] video {
        width: 500px !important;
        height: 300px !important;
    }
}

@media (max-width: 425px) {
    [data-type] iframe, [data-type] video {
        width: 360px !important;
        height: 260px !important;
    }
}

@media (max-width: 375px) {
    [data-type] iframe, [data-type] video {
        width: 100% !important;
        height: auto !important;
    }
}

input[type="date"]::-webkit-calendar-picker-indicator {
    cursor: pointer;
    border-radius: 4px;
    margin-right: 2px;
    opacity: 0.6;
    filter: invert(0.8);
    position: absolute;
    top: 12px;
    left: 10px;
    height: 22px;
    width: 22px;
  }
  select option {
    border: none !important;
  }